import { Link } from "gatsby";
import React from "react";
import { FaPhoneSquareAlt, FaRegEnvelope } from "react-icons/fa";
import styled from "styled-components";
import AppStoreLogo from "../assets/apple-app-store-icon.png";
import CheckoutLogo from "../assets/Checkout.com.png";
import googlePlayLogo from "../assets/get-it-on-google-play-badge.png";
import logo from "../assets/logo.png";
import paypalLogo from "../assets/paypal.png";
import SocialLinks from "../constants/socialLinks";

const Footer = () => {
    return ( 
        <div className="footer--wrapper"> 
           <StyleContiner className="section-center">
                <StyleLogoArea>
                    <div className="logo--left">
                        <Link to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                    <div className="info--right">
                        <div> 
                            <FaPhoneSquareAlt/> <a href="tel:306947484950"> +30 6947484950</a>
                        </div>
                        <div> 
                            <FaRegEnvelope/> <a  href="mailto:info@grondas.com"> info@grondas.com</a> 
                        </div>
                    </div>
                </StyleLogoArea>
                {/*<div className="footer--all-menu">*/}
                {/*    <div className="footer--main-menu">*/}
                {/*        <h3>About</h3>*/}
                {/*        <ul>*/}
                {/*            <li><a href="#">Company</a></li>*/}
                {/*            <li><a href="#">Blog</a></li>*/}
                {/*            <li><a href="#">Newsroom</a></li>*/}
                {/*            <li><a href="#">Terms of use</a></li>*/}
                {/*            <li><a href="/privacy/"> Privacy Policy</a></li>*/}
                {/*            <li><a href="#">Cookie Settings</a></li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="footer--main-menu">*/}
                {/*        <h3>Network</h3>*/}
                {/*        <ul>*/}
                {/*            <li><a href="#">Hotels</a></li>*/}
                {/*            <li><a href="#">Vacations Rentals</a></li>*/}
                {/*            <li><a href="#">Affiliates</a></li>*/}
                {/*            <li><a href="#">Individual Drivers</a></li>*/}
                {/*            <li><a href="#">Driver Companies</a></li> */}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="footer--main-menu">*/}
                {/*        <h3>About</h3>*/}
                {/*        <ul>*/}
                {/*            <li><a href="#">Company</a></li>*/}
                {/*            <li><a href="#">Blog</a></li>*/}
                {/*            <li><a href="#">Newsroom</a></li>*/}
                {/*            <li><a href="#">Terms of use</a></li>*/}
                {/*            <li><a href="#">Privacy Policy</a></li>*/}
                {/*            <li><a href="#">Cookie Settings</a></li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="footer--main-menu">*/}
                {/*        <h3>Network</h3>*/}
                {/*        <ul>*/}
                {/*            <li><a href="#">Hotels</a></li>*/}
                {/*            <li><a href="#">Vacations Rentals</a></li>*/}
                {/*            <li><a href="#">Affiliates</a></li>*/}
                {/*            <li><a href="#">Individual Drivers</a></li>*/}
                {/*            <li><a href="#">Driver Companies</a></li> */}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="footer--main-menu">*/}
                {/*        <h3>Traveler App</h3>*/}
                {/*        <ul>*/}
                {/*            <li><a href="#">*/}
                {/*                <img  src={AppStoreLogo}   alt="app store logo"/>*/}
                {/*                </a></li> */}
                {/*            <li><a href="#">*/}
                {/*                <img src={googlePlayLogo}   alt="google play logo"/>*/}
                {/*                </a></li> */}
                {/*            <li><a href="#">*/}
                {/*               <img src={paypalLogo }   alt="google play logo"/>*/}
                {/*                </a></li> */}
                {/*            <li><a href="#">*/}
                {/*                <img src={CheckoutLogo}   alt="checkout"/>*/}
                {/*                </a></li> */}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div class="footer--tourism">
                    <p>Member of the Greek Tourism Organization Registration <br/>Number: 6546549ASDJ</p>
                </div>
                <div class="footer--bottom">
                    <div class="copyright--menu">
                    <h4>
                        &copy;{new Date().getFullYear()}
                        <span> Grondas </span> all rights reserved
                    </h4>
                    <ul class="bottom--menu">
                        <li><a href="/terms">Term</a></li>
                        <li><a href="/privacy">Privacy Policy</a></li>
                        {/*<li><a href="#">Legal notice</a></li>*/}
                        {/*<li><a href="#">Accessibility</a></li>*/}
                    </ul>
                    </div>
                    <div class="social--link">
                    {/*<SocialLinks styleClass="footer-links"/>*/}
                    </div>
                </div>
           </StyleContiner> 
        </div>
    )
}

 
const StyleContiner = styled.div`
    width: 95vw;
    margin: 0 auto;
    @media (max-width: 767px) {
        width: 100%;
      }
`;
const StyleLogoArea = styled.div`
   display: flex; 
   justify-content: space-between;
   //border-bottom: 1px solid rgb(59, 59, 59);
   padding: 15px 0px;
   @media (max-width: 600px) {
    flex-direction: column; 
  }
`;
 

export default Footer