import { navigate } from "gatsby";
import React from "react";
import Helmet from 'react-helmet';
import favicon from '../assets/favicon.ico';
import { defaultOptions } from "../constants/toursDefaultValues";
import BookingFormModal from "./BookingFormModal/BookingFormModal";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [bookingModalOpen, setBookingModalOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const onBookingSubmit = (state, type, vehiclePrice, maxLuggage, maxPassengers, vehicleId, enumCategory) => {
        const data = {
            date: state.date,
            fromData: state.fromData,
            toData: state.toData,
            price: vehiclePrice,
            luggageCount: state.luggageCount ? state.luggageCount : 0,
            passengersCount: state.passengersCount ? state.passengersCount : 0,
            maxLuggage: maxLuggage ? maxLuggage : 0,
            maxPassengers: maxPassengers ? maxPassengers : 0,
            vehicleType: type,
            vehicleId: vehicleId,
            enumCategory: enumCategory,
        };

        localStorage.setItem("checkoutData", JSON.stringify(data));
        navigate("/checkout");
    };

    return (
        <>
            <Helmet>
                <link rel="icon" href={favicon} />
            </Helmet>
            <Navbar toggleSidebar={toggleSidebar} bookNowPress={() => setBookingModalOpen(true)} />
            <Sidebar
                isOpen={isOpen}
                toggleSidebar={toggleSidebar}
                bookNowPress={() => {
                    setBookingModalOpen(true);
                    toggleSidebar();
                }}
            />
            {children}
            <Footer />
            <BookingFormModal
                blockCloseOnSubmit={false}
                checkoutData={{}}
                defaultOptions={defaultOptions}
                onSubmit={onBookingSubmit}
                onClose={() => setBookingModalOpen(false)}
                show={bookingModalOpen}
                title={"Book A Transfer"}
            />
        </>
    );
};

export default Layout;
