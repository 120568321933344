import React from "react"
import { Link } from "gatsby"

const data = [
  // {
  //   id: 1,
  //   text: "home",
  //   url: "/",
  // },
  // {
  //   id: 2,
  //   text: "tours",
  //   url: "/tours/",
  // },
  // {
  //   id: 5,
  //   text: "fleet",
  //   url: "/fleet/",
  // },
  // {
  //   id: 3,
  //   text: "about",
  //   url: "/about/",
  // },
  // {
  //   id: 4,
  //   text: "contact",
  //   url: "/contact/",
  // },
]

const tempLinks = data.map(link => {
  return (
    <li key={link.id}>
      <Link to={link.url}>{link.text}</Link>
    </li>
  )
})

export default ({ styleClass }) => {
  return (
    <ul className={`page-links ${styleClass ? styleClass : ""}`}>
      {tempLinks}
    </ul>
  )
}
