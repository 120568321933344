import styled from "styled-components";
import { Link } from "gatsby";

const StyledButton = styled.button`
    position: relative;
    font-size: 1rem;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 0.5em;
    line-height: 1.5rem;
    background-color: #f6f6f6;
    outline: none;
    border: none;
    cursor: pointer;

    &.active {
        opacity: 0.7;
    }

    svg {
        width: 1.5rem;
        height: 1.5rem;
    }

    &.bookNow {
        background-color: var(--clr-primary-5);
        color: #fff;
        padding: 0.6rem 1rem;
        transition: 0.2s;
        white-space: nowrap;

        &:disabled {
            cursor: default;
        }
        &:hover {
            box-shadow: 0 0.5rem 0.5rem -0.3rem var(--clr-grey-6);
        }
        &.big {
            padding: 0.8rem 2rem;
        }
    }

    &.neutral {
        color: #565656;
        border: 1px solid transparent;
        font-weight: 600;
        transition: border 0.2s ease-in-out;
        &.active {
            opacity: 0.7;
        }
        &.edit {
            padding: 0.75em 1.5em;
        }
    }
    &.bold {
        font-weight: 600;
    }
    &.big {
        font-size: 1.25rem;
    }
    &.marginAuto {
        margin: auto;
    }
    &.horizontalMargin {
        margin: 0 5%;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`;

export { StyledButton, StyledLink };
