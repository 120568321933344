import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { FaAlignRight } from "react-icons/fa";
import PageLinks from "../constants/navBarLinks";
import { Link } from "gatsby";
import Button from "../components/UI/Button/Button";
import styled from "styled-components";

const Navbar = ({ toggleSidebar, bookNowPress }) => {
    const [scrollTop, setScrollTop] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, false);
        return () => {
            window.removeEventListener("scroll", handleScroll, false);
        };
    }, []);

    const handleScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > 0) {
            if (!scrollTop) setScrollTop(true);
        } else if (winScroll <= 0) {
            setScrollTop(false);
        }
    };

    return (
        <nav className={`navbar ${scrollTop ? "whiteBackground" : ""}`}>
            <div className="nav-center">
                <div className="nav-header">
                    <Link to="/">
                        <img src={logo} alt="logo" />
                    </Link>
                    <button type="button" className="toggle-btn" onClick={toggleSidebar}>
                        <FaAlignRight />
                    </button>
                </div>
                <div className="nav-items">
                    <PageLinks styleClass="nav-links" />
                    <div className={`navBookNowButtonWrapper ${scrollTop ? "animate" : ""}`}>
                        <Button disabled={!scrollTop} onClick={bookNowPress} className="bookNow bookNowModalButton">
                            Book now
                        </Button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
