import React, { memo } from "react";
import { StyledButton, StyledLink } from "./ButtonStyled";

const Button = ({ className, onClick, children, path, loading, asATag, ...rest }) => {
    const button = (
        <StyledButton {...rest} onClick={onClick} className={className}>
            {children}
        </StyledButton>
    );

    if (path) {
        return <StyledLink to={path}>{button}</StyledLink>;
    }

    return button;
};

export default memo(Button);
