import React from "react";
import Links from "../constants/navBarLinks";
import SocialLinks from "../constants/socialLinks";
import { FaTimes } from "react-icons/fa";
import Button from "../components/UI/Button/Button";

const Sidebar = ({ isOpen, toggleSidebar, bookNowPress }) => {
    return (
        <aside className={`sidebar ${isOpen ? "show-sidebar" : ""} `}>
            <button className="close-btn" onClick={toggleSidebar}>
                <FaTimes />
            </button>
            <div className="side-container">
                <Links styleClass={`${isOpen ? "sidebar-links" : ""}`} />
                <Button onClick={bookNowPress} className={`bookNow marginAuto big ${isOpen ? "bookNowSidebarButton" : ""}`}>
                    Book now
                </Button>
                <SocialLinks styleClass={`${isOpen ? "sidebar-icons" : ""}`} />
            </div>
        </aside>
    );
};

export default Sidebar;
